import type { TaigaUser } from '$lib/types/user';
import { hasPerm } from './permission';

export function shouldShowColumn(
  canHide: boolean,
  name: string,
  taigaUser: TaigaUser | null | undefined,
  permission?: string
) {
  return (
    (!canHide ||
      !taigaUser?.settings?.projectsTable.tableColumns ||
      taigaUser.settings.projectsTable.tableColumns.includes(name)) &&
    (!permission || hasPerm(taigaUser, permission))
  );
}
