<script lang="ts">
  import userStore from '$lib/stores/userStore';
  import type { NestedProjectResponse } from '$lib/types/response';
  import type { TaigaUser } from '$lib/types/user';
  import { Table, TableBody, TableBodyCell, TableBodyRow, TableHead, TableHeadCell } from 'flowbite-svelte';
  import { createEventDispatcher, onDestroy } from 'svelte';
  import PMButton from '../generic/PMButton/PMButton.svelte';

  export let invitedProjects: NestedProjectResponse[] = [];
  let user: TaigaUser | undefined | null;
  let isDarkTheme = false;

  let unsubscribe = userStore.subscribe((data) => {
    if (!data?.user) {
      return;
    }

    user = data.user as TaigaUser;
    isDarkTheme = document.documentElement.classList.contains('dark');
  });

  onDestroy(() => {
    unsubscribe();
  });

  const dispatch = createEventDispatcher();
</script>

<Table hoverable={true} divClass="overflow-visible pb-4">
  <TableHead>
    <TableHeadCell>Name</TableHeadCell>
    <TableHeadCell />
  </TableHead>
  <TableBody>
    {#each invitedProjects as project (project.id)}
      <TableBodyRow class="cursor-pointer">
        <TableBodyCell>{project.name}</TableBodyCell>
        <TableBodyCell tdClass="px-6 py-4 whitespace-nowrap font-medium text-gray-900 dark:text-white flex justify-end">
          <PMButton
            text="Accept"
            classes="h-9"
            on:click={() => {
              dispatch('handleAccept', project.id);
            }}
          />
        </TableBodyCell>
      </TableBodyRow>
    {/each}
  </TableBody>
</Table>
