<script lang="ts">
  import navbarStore from '$lib/stores/navbarStore';
  import type { Column } from '$lib/types/project';
  import { toast } from '@zerodevx/svelte-toast';
  import axios from 'axios';
  import { Modal } from 'flowbite-svelte';
  import { createEventDispatcher, onMount } from 'svelte';
  import PMButton from '../generic/PMButton/PMButton.svelte';

  export let columns: Column[];
  let arePreferencesOpen = false;
  const dispatch = createEventDispatcher();

  onMount(() => {
    navbarStore.subscribe((data) => {
      arePreferencesOpen = data.arePreferencesOpen;
    });
  });

  const updateNavbarStore = (open: boolean) => {
    navbarStore.set({ arePreferencesOpen: open });
  };

  $: updateNavbarStore(arePreferencesOpen);

  async function savePreferences() {
    try {
      dispatch('saveUserSettings');
      dispatch('setColumns');
      navbarStore.set({ arePreferencesOpen: false });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.push(e.message);
        return;
      }
      throw e;
    }
  }

  function handleColumnChange(event: Event, name: string) {
    const target = event.target as HTMLInputElement;
    const newColumns = columns.map((column) => {
      if (column.name !== name) {
        return column;
      }
      column.isHidden = !target.checked;
      return column;
    });
    dispatch('setColumns', newColumns);
  }
</script>

<Modal title="Preferences" bind:open={arePreferencesOpen} autoclose outsideclose>
  <div class="w-full h-full flex flex-col">
    <h1 class="text-lg font-bold">Table columns</h1>
    {#each columns as column (column.name)}
      <div class="flex justify-between">
        <div>{column.name}</div>
        <input
          id="checkbox"
          type="checkbox"
          checked={!column.isHidden}
          on:change={(e) => handleColumnChange(e, column.name)}
          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
    {/each}
  </div>
  <svelte:fragment slot="footer">
    <PMButton text="Save" on:click={savePreferences} />
  </svelte:fragment>
</Modal>
