import { writable } from 'svelte/store';

const navbarStore = writable<{
  arePreferencesOpen: boolean;
}>({ arePreferencesOpen: false });

export default {
  subscribe: navbarStore.subscribe,
  update: navbarStore.update,
  set: navbarStore.set,
};
