<script lang="ts">
  import * as projectService from '$lib/services/project';
  import taigaEventStore from '$lib/stores/taigaEventStore';
  import type { GeneralInformationUpdateContent } from '$lib/types/taiga-events';
  import { Modal } from 'flowbite-svelte';
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import Markdown from '../generic/Markdown.svelte';
  export let isOpen = false;
  export let workspaceId = '';
  let source = '';
  const dispatch = createEventDispatcher();

  $: if (workspaceId) {
    fetchGeneralInformation();
  }

  $: {
    dispatch('handleShowModal', isOpen);
  }

  const onGeneralInformationUpdate = (updateContent: GeneralInformationUpdateContent) => {
    source = updateContent.generalInformation ?? '';
  };

  const fetchGeneralInformation = async () => {
    const data = await projectService.getWorkspaceMembership(workspaceId);
    source = data.generalInformation;
  };

  onMount(() => {
    taigaEventStore.subscribeToGeneralInformationChangeEvents(onGeneralInformationUpdate);
  });

  onDestroy(() => {
    taigaEventStore.unsubscribeFromGeneralInformationChangeEvents(onGeneralInformationUpdate);
  });
</script>

<Modal title="General Overview" bind:open={isOpen} autoclose outsideclose>
  <Markdown source={source || 'General Overview for projects is currently unavailable.'} />
</Modal>
