<script lang="ts">
  import type { ProjectStatus } from '$lib/enums/project';
  import { PROJECT_STATUS } from '$lib/utils/constants';
  export let numberOfProjects: number;
  export let status: ProjectStatus;

  $: statusText = PROJECT_STATUS[status].text;
  $: statusColor = PROJECT_STATUS[status].color;
</script>

<div class="bg-white dark:bg-gray-800 dark:border-gray-600 p-2 m-1 border rounded flex items-center">
  <span class="text-xl text-ai8-light-gray">{numberOfProjects}</span>
  <span class={`mx-1 w-[8px] h-[8px] rounded-full`} style={`background-color: var(--color-ai8-${statusColor});`} />
  <span class="font-semibold">{statusText}</span>
</div>
