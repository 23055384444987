<script lang="ts">
  import { goto } from '$app/navigation';
  import EmptyPage from '$lib/components/generic/EmptyPage.svelte';
  import Spinner from '$lib/components/generic/Spinner.svelte';
  import Table from '$lib/components/projectBoard/allProjects/AllProjectsTable.svelte';
  import GeneralInformationModal from '$lib/components/projectBoard/allProjects/GeneralInformationModal.svelte';
  import InvitedProjectsTable from '$lib/components/projectBoard/allProjects/InvitedProjectsTable.svelte';
  import PreferenceModal from '$lib/components/projectBoard/allProjects/PreferenceModal.svelte';
  import StatusOverviewCard from '$lib/components/projectBoard/allProjects/StatusOverviewCard.svelte';
  import PMButton from '$lib/components/projectBoard/generic/PMButton/PMButton.svelte';
  import { PageName } from '$lib/enums/feedback';
  import { ProjectOrderByOption, ProjectStatus } from '$lib/enums/project';
  import * as authService from '$lib/services/auth';
  import * as projectService from '$lib/services/project';
  import feedbackStore from '$lib/stores/feedbackStore';
  import { closeChatMenu } from '$lib/stores/symStore';
  import taigaEventStore from '$lib/stores/taigaEventStore';
  import userStore from '$lib/stores/userStore';
  import workspaceStore from '$lib/stores/workspaceStore';
  import type { AllProjectPageData } from '$lib/types/page';
  import type { ProjectSummary } from '$lib/types/project';
  import type { NestedProjectResponse, UserSettingsResponse } from '$lib/types/response';
  import type { ProjectTableSettings } from '$lib/types/table';
  import type { ProjectStatusDataUpdateContent, ProjectUpdateEventContent } from '$lib/types/taiga-events';
  import type { TaigaUser } from '$lib/types/user';
  import { AllProjectColumnsConfig, ProjectTableSettingsConfig } from '$lib/utils/config';
  import { PROJECT_STATUS, TaigaPermissions } from '$lib/utils/constants';
  import { hasPerm } from '$lib/utils/permission';
  import { getProjectStatus } from '$lib/utils/status';
  import { parseISO } from 'date-fns';
  import { cloneDeep, debounce } from 'lodash-es';
  import { onDestroy, onMount } from 'svelte';

  type Statistics = {
    [key: string]: number;
  };
  export let data: AllProjectPageData;

  let tableSettings: ProjectTableSettings = { ...ProjectTableSettingsConfig };
  let projects: ProjectSummary[] = [];
  let invitedProjects: NestedProjectResponse[] = [];
  let user: TaigaUser | undefined | null;
  let showGeneralInformation = false;
  let statistics: Statistics | null = null;
  let loading: boolean = true;
  let workspaceExists: boolean = true;
  let columns = cloneDeep(AllProjectColumnsConfig).filter((column) => column.canHide);
  let monitoredProjects: string[] = [];

  let unsubscribe = userStore.subscribe((data) => {
    if (!data?.user) {
      return;
    }
    user = data.user as TaigaUser;
    if (!user.workspacePermissions) {
      return;
    }

    setColumns();
    setFilters();
  });

  const onProjectUpdate = (updateContent: ProjectUpdateEventContent) => {
    // Update list data
    projects = projects.map((p) => {
      if (p.id === updateContent.project) {
        return {
          ...p,
          progress: updateContent.progress,
        };
      }
      return p;
    });

    // Check if the subscription is still needed
    if (updateContent.progress === 100) {
      taigaEventStore.unsubscribeFromProjectUpdateEvents(updateContent.project, onProjectUpdate);
      taigaEventStore.unsubscribeFromProjectEvents(updateContent.project);
      monitoredProjects = monitoredProjects.filter((p) => p !== updateContent.project);
    }
  };

  const subscribeToAllProjectChanges = () => {
    // NOTE: This assumes the function is called after fetching the projects
    projects
      .filter((p) => p.startsAt && new Date() >= parseISO(p.startsAt))
      .forEach((p) => {
        taigaEventStore.subscribeToProjectEvents(p.id);
        taigaEventStore.subscribeToProjectUpdateEvents(p.id, onProjectUpdate);
        monitoredProjects.push(p.id);
      });
  };

  const unsubscribeFromAllProjectChanges = () => {
    monitoredProjects.forEach((projectId) => {
      taigaEventStore.unsubscribeFromProjectUpdateEvents(projectId, onProjectUpdate);
      taigaEventStore.unsubscribeFromProjectEvents(projectId);
    });
    monitoredProjects = [];
  };

  const onProjectStatusDataUpdate = (updateContent: ProjectStatusDataUpdateContent) => {
    projects = projects.map((p) => {
      if (p.id === updateContent.project) {
        return {
          ...p,
          statusScore: updateContent.statusScore,
          status: getProjectStatus(updateContent.statusScore),
          notes: updateContent.notes,
        };
      }
      return p;
    });
  };

  onMount(async () => {
    closeChatMenu();
    await fetchPermissions();
    invitedProjects = cloneDeep(data.invitedProjects);
    loading = false;
    subscribeToAllProjectChanges();
    taigaEventStore.subscribeToProjectStatusDataChangeEvents(onProjectStatusDataUpdate);
    feedbackStore.set({ pageName: PageName.ALL_PROJECTS });
  });

  onDestroy(() => {
    unsubscribe();
    unsubscribeFromAllProjectChanges();
    taigaEventStore.unsubscribeFromProjectStatusDataChangeEvents(onProjectStatusDataUpdate);
  });

  function handleRowClick(event: CustomEvent) {
    goto(`/projects/${event.detail.id}`);
  }

  async function handleAccept(event: CustomEvent) {
    const data = await projectService.acceptProjectInvitation(event.detail);
    await fetchProjects();
    invitedProjects = [...invitedProjects.filter((p) => p.id !== data.project.id)];
  }

  const debouncedFetchProjects = debounce(fetchProjects, 500);
  const debouncedSaveUserSettings = debounce(saveUserSettings, 500);

  function handleSearch(e: CustomEvent) {
    if (tableSettings.searchValue === e.detail) {
      return;
    }
    tableSettings = { ...tableSettings, searchValue: e.detail };
    debouncedFetchProjects();
    debouncedSaveUserSettings(false);
  }

  async function handleSort(e: CustomEvent) {
    const key = e.detail;
    if (tableSettings.orderBy === key) {
      tableSettings = { ...tableSettings, orderBy: ('-' + tableSettings.orderBy) as ProjectOrderByOption };
    } else {
      tableSettings = { ...tableSettings, orderBy: key as ProjectOrderByOption };
    }
    await fetchProjects();
    saveUserSettings();
  }

  async function handleFilter(e: CustomEvent) {
    let statusFilter = e.detail.statusFilter;

    tableSettings = {
      ...tableSettings,
      customFilter: e.detail.customFilter,
      statusFilter,
    };
    await fetchProjects();
    saveUserSettings();
  }

  function handleShowModal(e: CustomEvent) {
    showGeneralInformation = e.detail;
  }

  async function fetchPermissions() {
    const workspaceId = data?.workspaceId;
    if (!workspaceId) {
      console.error('No workspace ID found');
      return;
    }
    workspaceStore.set({ workspaceId: workspaceId });

    const rawPermissions = await projectService.getWorkspacePermissions(workspaceId);
    userStore.update((u) => {
      if (u?.user) {
        (u.user as TaigaUser).workspacePermissions = rawPermissions;
      }
      return u;
    });
  }

  async function fetchProjects() {
    const workspaceId = data?.workspaceId;
    if (!workspaceId) {
      console.error('No workspace ID found');
      workspaceExists = false;
      return;
    }

    const queryParams: URLSearchParams = buildQueryParams();
    try {
      projects = await projectService.getProjects(workspaceId, queryParams);
      statistics = {};
      statistics[ProjectStatus.ON_TRACK] = countProjectsByStatus(ProjectStatus.ON_TRACK);
      statistics[ProjectStatus.CAUTION] = countProjectsByStatus(ProjectStatus.CAUTION);
      statistics[ProjectStatus.BLOCKED] = countProjectsByStatus(ProjectStatus.BLOCKED);
    } catch (e) {
      console.error(e);
    }
  }

  function countProjectsByStatus(status: ProjectStatus): number {
    return projects.filter((project) => project.status === status).length;
  }

  function buildQueryParams(): URLSearchParams {
    const queryParams = new URLSearchParams();
    queryParams.append('order_by', tableSettings.orderBy);
    queryParams.append('search_value', tableSettings.searchValue);
    const statusScoreRange =
      tableSettings.statusFilter in PROJECT_STATUS
        ? PROJECT_STATUS[tableSettings.statusFilter].statusScoreRange
        : { minStatusScore: 0, maxStatusScore: 1 };
    queryParams.append('min_status_score', statusScoreRange.minStatusScore.toString());
    queryParams.append('max_status_score', statusScoreRange.maxStatusScore.toString());

    if (!tableSettings.customFilter) {
      return queryParams;
    }

    const filter = user!.projectFilters.find((f) => f.name === tableSettings.customFilter);
    if (!filter) {
      return queryParams;
    }

    filter.projectIds.forEach((id) => {
      queryParams.append('id__in', id.toString());
    });

    return queryParams;
  }

  async function saveUserSettings(updateStore = true): Promise<UserSettingsResponse> {
    const data = await authService.updateTaigaCurrentUser({
      projectsTable: {
        tableColumns: columns.filter((column) => !column.isHidden).map((column) => column.name),
        orderBy: tableSettings.orderBy,
        searchValue: tableSettings.searchValue,
        statusFilter: tableSettings.statusFilter,
        customFilter: tableSettings.customFilter,
      },
    });
    if (!updateStore) {
      return data;
    }

    userStore.update((u) => {
      (u.user as TaigaUser).settings = data;
      return u;
    });
    return data;
  }

  function setFilters() {
    const projectsTable = user!.settings?.projectsTable;
    if (!projectsTable) {
      fetchProjects();
      return;
    }
    tableSettings = {
      ...tableSettings,
      orderBy: projectsTable.orderBy as ProjectOrderByOption,
      searchValue: projectsTable.searchValue,
      statusFilter: projectsTable.statusFilter,
      customFilter: projectsTable.customFilter,
    };
    fetchProjects();
  }

  function setColumns(event?: CustomEvent) {
    if (event?.detail) {
      columns = event.detail;
      return;
    }

    columns = columns.filter((column) => !column?.permission || hasPerm(user, column.permission));
    columns = columns.map((column) => {
      const userData = user as TaigaUser;
      column.isHidden = !!userData.settings && !userData.settings.projectsTable.tableColumns.includes(column.name);
      return column;
    });
  }
</script>

{#if !loading && workspaceExists}
  <div class="px-8 pt-8 bg-ai8-white dark:bg-ai8-chat-gray">
    {#if hasPerm(user, TaigaPermissions.ACCESS_PROJECT_STATUS) || hasPerm(user, TaigaPermissions.ACCESS_PROJECTS_GENERAL_OVERVIEW)}
      <div class="bg-white dark:bg-gray-700 p-8 mb-8 rounded flex justify-between">
        <div class="flex">
          {#if hasPerm(user, TaigaPermissions.ACCESS_PROJECT_STATUS) && statistics}
            <StatusOverviewCard numberOfProjects={statistics[ProjectStatus.ON_TRACK]} status={ProjectStatus.ON_TRACK} />
            <StatusOverviewCard numberOfProjects={statistics[ProjectStatus.CAUTION]} status={ProjectStatus.CAUTION} />
            <StatusOverviewCard numberOfProjects={statistics[ProjectStatus.BLOCKED]} status={ProjectStatus.BLOCKED} />
          {/if}
        </div>
        {#if hasPerm(user, TaigaPermissions.ACCESS_PROJECTS_GENERAL_OVERVIEW)}
          <PMButton
            text="General Information"
            on:click={() => {
              showGeneralInformation = true;
            }}
          />
        {/if}
      </div>
    {/if}
    {#if invitedProjects.length}
      <h1 class="text-xl font-bold mb-4">Invited Projects</h1>
      <InvitedProjectsTable {invitedProjects} on:handleAccept={handleAccept} />
    {/if}
    <h1 class="text-xl font-bold mb-4">All Projects</h1>
    <Table
      {projects}
      {user}
      {tableSettings}
      on:handleSearch={handleSearch}
      on:rowClick={handleRowClick}
      on:handleFilter={handleFilter}
      on:handleSort={handleSort}
    />
  </div>
  <GeneralInformationModal
    on:handleShowModal={handleShowModal}
    isOpen={showGeneralInformation}
    workspaceId={data?.workspaceId}
  />
  <PreferenceModal {columns} on:setColumns={setColumns} on:saveUserSettings={() => saveUserSettings()} />
{:else if loading}
  <div class="flex items-center justify-center w-full h-screen"><Spinner /></div>
{:else}
  <EmptyPage title="No Workspaces Available" description="You are not added to any workspaces yet." />
{/if}
