<script lang="ts">
  import Ai8Icon from '$lib/assets/icons/ai8-icon.svg';

  export let title: string;
  export let description: string;
</script>

<section class="bg-white dark:bg-gray-900 h-screen">
  <div class="container flex items-center min-h-screen px-6 py-12 mx-auto">
    <div class="flex flex-col items-center max-w-sm mx-auto text-center">
      <img src={Ai8Icon} alt="AI8-Icon" width="80px" />
      <h1 class="mt-1 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">
        {title}
      </h1>
      <p class="mt-4 text-gray-500 dark:text-gray-400">
        {description}
      </p>
    </div>
  </div>
</section>
