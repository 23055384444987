<script lang="ts">
  import NoResultsFound from '$lib/components/generic/NoResultsFound.svelte';
  import TableSortIcon from '$lib/components/generic/table/TableSortIcon.svelte';
  import { AllProjectColumns, ProjectStatus } from '$lib/enums/project';
  import navbarStore from '$lib/stores/navbarStore';
  import type { Column, ProjectSummary } from '$lib/types/project';
  import type { ProjectTableSettings } from '$lib/types/table';
  import type { TaigaUser } from '$lib/types/user';
  import { shouldShowColumn } from '$lib/utils/column';
  import { AllProjectColumnsConfig, ProjectTableSettingsConfig } from '$lib/utils/config';
  import { PROJECT_STATUS, TaigaPermissions } from '$lib/utils/constants';
  import { hasPerm } from '$lib/utils/permission';
  import {
    Progressbar,
    Select,
    Table,
    TableBody,
    TableBodyCell,
    TableBodyRow,
    TableHead,
    TableHeadCell,
  } from 'flowbite-svelte';
  import { cloneDeep } from 'lodash-es';
  import { createEventDispatcher } from 'svelte';
  import { FileContractSolid, GearSolid } from 'svelte-awesome-icons';
  import SearchInput from '../../generic/SearchInput.svelte';
  import NotesModal from './NotesModal.svelte';

  export let projects: ProjectSummary[] = [];
  export let user: TaigaUser | undefined | null;
  export let tableSettings: ProjectTableSettings;

  let columns: Column[] = [];
  let notesModalShownFor: ProjectSummary | null = null;

  const dispatch = createEventDispatcher();
  const statusOptions = [ProjectTableSettingsConfig.statusFilter, ...Object.values(ProjectStatus)].map((status) => ({
    value: status,
    name: status,
  }));

  function handlePreferencesModal() {
    navbarStore.set({ arePreferencesOpen: true });
  }

  function handleRowClick(row: ProjectSummary) {
    dispatch('rowClick', row);
  }

  const handleSearch = (event: CustomEvent) => {
    dispatch('handleSearch', event.detail);
  };

  const handleStatusFilter = (event: Event) => {
    const value = (event.target as HTMLSelectElement).value;
    dispatch('handleFilter', { statusFilter: value, customFilter: tableSettings.customFilter });
  };

  const handleCustomFilter = (event: Event) => {
    const value = (event.target as HTMLSelectElement).value;
    dispatch('handleFilter', { statusFilter: tableSettings.statusFilter, customFilter: value });
  };

  $: if (projects) {
    // Handling the project with open notes
    if (notesModalShownFor) {
      notesModalShownFor = projects.find((project) => project.id === notesModalShownFor!.id) ?? null;
    }
  }

  function openNotesModal(event: Event, project: ProjectSummary) {
    event.stopPropagation();
    if (project.notes) {
      notesModalShownFor = project;
    }
  }

  function getProjectProgressColor(progress: number): 'red' | 'yellow' | 'green' {
    return progress < 33 ? 'red' : progress < 66 ? 'yellow' : 'green';
  }

  $: if (user) {
    columns = cloneDeep(AllProjectColumnsConfig).filter((c) => shouldShowColumn(c.canHide, c.name, user, c.permission));
  }

  const getCustomFiltersOptions = () => {
    const noFilter = { name: ProjectTableSettingsConfig.customFilter, value: ProjectTableSettingsConfig.customFilter };
    const projectFilters = user!.projectFilters;
    if (projectFilters.length) {
      return [
        noFilter,
        ...projectFilters.map((filter) => ({
          name: filter.name,
          value: filter.name,
        })),
      ];
    }
    return [noFilter];
  };
</script>

<div class="flex pb-4 justify-between">
  <div class="flex">
    <SearchInput searchPlaceholder="Search" value={tableSettings.searchValue} on:search={handleSearch} />
    {#if hasPerm(user, TaigaPermissions.ACCESS_PROJECT_STATUS)}
      <Select
        items={statusOptions}
        on:change={handleStatusFilter}
        value={tableSettings.statusFilter}
        placeholder=""
        class="!font-semibold text-gray-900 dark:text-gray-300 text-sm !mx-2 w-40"
      />
    {/if}
    {#if hasPerm(user, TaigaPermissions.CAN_USE_CUSTOM_FILTERS)}
      <Select
        items={getCustomFiltersOptions()}
        on:change={handleCustomFilter}
        value={tableSettings.customFilter}
        placeholder=""
        class="!font-semibold text-gray-900 dark:text-gray-300 text-sm !mx-4 w-40"
      />
    {/if}
  </div>
  <button on:click={handlePreferencesModal} class="rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 p-1">
    <GearSolid size="24" class="text-gray-800 dark:text-gray-300 outline-none" />
  </button>
</div>
<Table hoverable={true} divClass="overflow-visible pb-4">
  <TableHead>
    {#key columns.length}
      {#each columns as column (column.name)}
        {#if column?.sort}
          <TableHeadCell
            on:click={() => dispatch('handleSort', column.sort?.[0])}
            class="cursor-pointer min-w-[8rem] max-w-[8rem] !pr-3 !pl-6 !py-2"
          >
            <span class="flex w-full uppercase">
              {column.name}
              {#if column.sort.includes(tableSettings.orderBy)}
                <TableSortIcon orderBy={tableSettings.orderBy} />
              {/if}
            </span></TableHeadCell
          >
        {:else}
          <TableHeadCell>{column.name}</TableHeadCell>
        {/if}
      {/each}
    {/key}
  </TableHead>
  <TableBody>
    {#if projects.length > 0}
      {#key columns.length}
        {#each projects as project (project.id)}
          <TableBodyRow on:click={() => handleRowClick(project)} class="cursor-pointer">
            {#if shouldShowColumn(false, AllProjectColumns.NAME, user)}
              <TableBodyCell>{project.name}</TableBodyCell>
            {/if}
            {#if shouldShowColumn(true, AllProjectColumns.PROGRESS, user, TaigaPermissions.ACCESS_PROJECT_PROGRESS)}
              <TableBodyCell tdClass="pl-6 pr-4">
                <Progressbar
                  progress={project.progress.toString()}
                  color={getProjectProgressColor(project.progress)}
                  size="h-6"
                  labelInside
                  labelInsideClass="text-xs font-medium text-center leading-none rounded-full h-full pt-1.5"
                /></TableBodyCell
              >
            {/if}
            {#if shouldShowColumn(true, AllProjectColumns.COMPANY, user)}
              <TableBodyCell>{project.companyName}</TableBodyCell>
            {/if}
            {#if shouldShowColumn(true, AllProjectColumns.STUDENT, user, TaigaPermissions.ACCESS_PROJECT_STUDENT_NAME)}
              <TableBodyCell>{project.studentName}</TableBodyCell>
            {/if}
            {#if shouldShowColumn(false, AllProjectColumns.STATUS, user, TaigaPermissions.ACCESS_PROJECT_STATUS)}
              <TableBodyCell>
                <div
                  class={`flex items-center w-fit p-2 pr-3 rounded-full`}
                  style={`color: var(--color-ai8-${
                    PROJECT_STATUS[project.status].color
                  }); background-color: var(--color-ai8-light-${PROJECT_STATUS[project.status].color});`}
                >
                  <span
                    class={`mx-1 w-[6px] h-[6px] rounded-full bg-ai8-green`}
                    style={`background-color: var(--color-ai8-${PROJECT_STATUS[project.status].color})`}
                  />{PROJECT_STATUS[project.status].text}
                </div></TableBodyCell
              >
            {/if}
            {#if shouldShowColumn(true, AllProjectColumns.NOTES, user, TaigaPermissions.ACCESS_PROJECT_NOTES)}
              <TableBodyCell on:click={(event) => openNotesModal(event, project)}>
                <div id={`note-${project.id}`} class="ml-3">
                  {#if project.notes}
                    <FileContractSolid
                      size="16"
                      class="text-gray-800 hover:text-emerald-600 dark:hover:text-emerald-600 dark:text-white"
                    />
                  {:else}
                    <FileContractSolid size="16" class="text-gray-400 dark:text-gray-500 cursor-default" />
                  {/if}
                </div></TableBodyCell
              >
            {/if}
          </TableBodyRow>
        {/each}
      {/key}
    {:else}
      <tr>
        <td colspan="7"><NoResultsFound /></td>
      </tr>
    {/if}
  </TableBody>
</Table>
<NotesModal
  notes={notesModalShownFor?.notes}
  isOpen={!!notesModalShownFor}
  on:handleShowModal={(event) => (notesModalShownFor = event.detail ? notesModalShownFor : null)}
/>
