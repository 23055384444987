// since there's no dynamic data here, we can prerender

import * as workflowService from '$lib/services/project';

// it so that it gets served as a static asset in production
export const prerender = true;

export async function load() {
  try {
    return await workflowService.getWorkspace();
  } catch (e) {
    return { e };
  }
}
