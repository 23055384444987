<script lang="ts">
  import type { ProjectOrderByOption } from '$lib/enums/project';
  import { CaretDownSolid, CaretUpSolid } from 'svelte-awesome-icons';

  export let orderBy: ProjectOrderByOption;

  let isDarkTheme = document.documentElement.classList.contains('dark');
</script>

<div class="flex items-center justify-center px-2">
  {#if orderBy.includes('-')}
    <CaretDownSolid size="10" color={isDarkTheme ? 'white' : 'black'} />
  {:else}
    <CaretUpSolid size="10" color={isDarkTheme ? 'white' : 'black'} />
  {/if}
</div>
